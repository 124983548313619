import React from 'react'

export default (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      id="genericLogo"
      fill="lightgray"
      stroke="none"
      strokeWidth="0"
      d="M 3.03,5.00
            C 3.03,5.00 10.03,4.00 12.03,2.00
              14.03,4.00 21.03,5.00 21.03,5.00
              21.03,5.00 22.03,18.00 12.03,22.00
              2.03,18.00 3.03,5.00 3.03,5.00M 0.00,0.00"
    />
  </svg>
)
