import { SetState } from 'src/common/types'
import { useSwipeable, EventData } from 'react-swipeable'

function useSwipeNav<T>(
  setTabValue: SetState<T>,
  values: Readonly<T[]> | T[],
  disableSwipe: boolean = false
) {
  const swipeableConfig = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
  }

  const handleSwipe = (evenData: EventData) => {
    if (disableSwipe) return

    const { dir, absX, absY, velocity } = evenData

    //trial-and-error values to ignore unintentional swipes
    if (
      velocity < 0.8 ||
      (dir !== 'Left' && dir !== 'Right') ||
      absX / absY < 5
    ) {
      return
    }

    if (dir === 'Left') {
      setTabValue((value) => {
        if (value === values[values.length - 1]) {
          return value
        }
        return values[values.indexOf(value) + 1]
      })
    }
    if (dir === 'Right') {
      setTabValue((value) => {
        if (value === values[0]) {
          return value
        }
        return values[values.indexOf(value) - 1]
      })
    }
  }

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
    ...swipeableConfig,
  })

  return handlers
}

export default useSwipeNav
